import * as Sentry from '@sentry/react'
import { Fragment, lazy, useContext, useEffect, useState } from 'react'
import { Redirect, Route, Switch, useLocation, useRoute } from 'wouter'

import { stopAnalyticsTracking, trackPageview } from './analytics'
import { Loader } from './components/Loader'
import {
  onAuthStateChanged,
  signInWithCustomToken,
  startRefreshingIdToken,
} from './firebase/auth'
import { LoadingContext } from './LoadingContext'
import { hasRequiredActions } from './pages/authentication/utils/requiredActions'
import {
  deleteTokenCookie,
  getTokenCookie,
  setTokenCookie,
} from './utils/cookies'
import { isCaptegoEmail } from './utils/email'
import { isMaintenance } from './utils/globalModes'
import { redirectToAdmin } from './utils/redirection'
import { redactIDFromURL } from './utils/url'

import type { ReactElement } from 'react'

const AuthPage = lazy(
  () =>
    import(
      /* webpackChunkName: "AuthPage" */ './pages/authenticationv2/AuthPage'
    )
)
const LoginPage = lazy(
  () =>
    import(
      /* webpackChunkName: "LoginPage" */ './pages/authenticationv2/login/LoginPage'
    )
)
const RequiredActionsPage = lazy(
  () =>
    import(
      /* webpackChunkName: "RequiredActionsPage" */ './pages/authenticationv2/requiredActions/RequiredActionsPage'
    )
)
const ContactPage = lazy(
  () =>
    import(
      /* webpackChunkName: "ContactPage" */ './pages/authenticationv2/contact/ContactPage'
    )
)
const LoginSuccessPage = lazy(
  () =>
    import(
      /* webpackChunkName: "LoginSuccessPage" */ './pages/authenticationv2/login/LoginSuccessPage'
    )
)
const InvitedPage = lazy(
  () =>
    import(
      /* webpackChunkName: "InvitedPage" */ './pages/authenticationv2/signup/InvitedPage'
    )
)
const SignUpPage = lazy(
  () =>
    import(
      /* webpackChunkName: "SignUpPage" */ './pages/authenticationv2/signup/SignUpPage'
    )
)

const SignOutPage = lazy(
  () =>
    import(
      /* webpackChunkName: "SignOutPage" */ './pages/authentication/SignOutPage'
    )
)
const AuthenticationPage = lazy(
  () =>
    import(
      /* webpackChunkName: "AuthenticationPage" */ './pages/authentication/AuthenticationPage'
    )
)
const SubscriptionPage = lazy(
  () =>
    import(
      /* webpackChunkName: "SubscriptionPage" */ './pages/company/SubscriptionPage'
    )
)
const CompanyPlansPage = lazy(
  () =>
    import(
      /* webpackChunkName: "CompanyPlansPage" */ './pages/company/plans/PlansPage'
    )
)
const CompanyPlansSuccessPage = lazy(
  () =>
    import(
      /* webpackChunkName: "CompanyPlansSuccessPage" */ './pages/company/plans/PlansSuccessPage'
    )
)
const CompanyPlansCanceledPage = lazy(
  () =>
    import(
      /* webpackChunkName: "CompanyPlansCanceledPage" */ './pages/company/plans/PlansCanceledPage'
    )
)

const RemoteCapturePage = lazy(
  () =>
    import(
      /* webpackChunkName: "RemoteCapturePage" */ './pages/remotecapture/RemoteCapturePage'
    )
)

const MaintenancePage = lazy(
  () =>
    import(
      /* webpackChunkName: "MaintenancePage" */ './pages/maintenance/MaintenancePage'
    )
)

const ReportsEditPage = lazy(
  () =>
    import(
      /* webpackChunkName: "ReportsEditPage" */ './pages/reports/edit/ReportsEditPage'
    )
)

const PreviewReportsPage = lazy(
  () =>
    import(
      /* webpackChunkName: "NewReportsPage" */ './pages/newReports/previewReportsPage'
    )
)

const ReportsPage = lazy(
  () =>
    import(
      /* webpackChunkName: "NewReportsPage" */ './pages/newReports/reportsPage'
    )
)

const ReportTemplatesPage = lazy(
  () =>
    import(
      /* webpackChunkName: "NewReportsPage" */ './pages/newReports/reportTemplatesPage'
    )
)

const ReportsViewPage = lazy(
  () =>
    import(
      /* webpackChunkName: "ReportsViewPage" */ './pages/reports/view/ReportsViewPage'
    )
)
const ReportsListPage = lazy(
  () =>
    import(
      /* webpackChunkName: "ReportsListPage" */ './pages/reports/list/ReportsListPage'
    )
)
const NewReportPage = lazy(
  () =>
    import(
      /* webpackChunkName: "NewReportPage" */ './pages/reports/new/NewReportPage'
    )
)

const AUTHENTICATION_V1 = import.meta.env.REACT_APP_AUTHENTICATION_V1 === 'true'
const DEFAULT_AUTH_PAGE = AUTHENTICATION_V1 ? '/authentication-v1' : '/auth'

/**
 * Handles routing in the app but also synchronizes Firebase Auth
 */
export function AppRouter(): ReactElement {
  document.title = `Captego Studio`

  const [_location, setLocation] = useLocation()
  const [isLoading, setLoading] = useState<boolean>(true)
  const [isAuthenticated, setAuthenticated] = useState<boolean>(false)

  const [{ isLoading: isLoadingFromContext }] = useContext(LoadingContext)

  const [isRequiredActionsPage] = useRoute('/auth/required-actions')

  /**
   * The matcher implemented by wouter is very basic
   * and doesn't support the `(abc|def)` syntax so we do it like this
   */
  const [isAuthV1] = useRoute('/authentication-v1')
  const [isAuthV2Login] = useRoute('/auth/login')
  const [isAuthV2Success] = useRoute('/auth/success')
  const [isAuthV2SignupInvited] = useRoute('/auth/signup/invited')
  const [isAuthV2Signup] = useRoute('/auth/signup')
  const [isAuthV2] = useRoute('/auth')
  const isAuthenticationPage =
    isAuthV1 ||
    isAuthV2Login ||
    isAuthV2Success ||
    isAuthV2SignupInvited ||
    isAuthV2Signup ||
    isAuthV2

  const [isSignOutPage] = useRoute('/signout')
  const [isRoot] = useRoute('/')

  useEffect(() => {
    trackPageview({
      url: redactIDFromURL(_location),
    })
  }, [_location])

  useEffect(() => {
    if (isSignOutPage) {
      setLoading(false)
      return
    }

    const unsubscribe = onAuthStateChanged(async (user) => {
      if (user != null) {
        /** Do not track anything for internal users */
        if (user.email != null && isCaptegoEmail(user.email)) {
          stopAnalyticsTracking()
        }

        setAuthenticated(true)
        Sentry.setUser({ id: user.uid })

        await startRefreshingIdToken()

        if (!hasRequiredActions()) {
          if (isAuthenticationPage || isRoot) {
            redirectToAdmin()
          } else {
            setLoading(false)
          }
        } else if (!isAuthV1 && !isRequiredActionsPage) {
          setLocation('/auth/required-actions')
        } else {
          setLoading(false)
        }
        return
      }

      let token = getTokenCookie()
      // Possible token in `customToken` GET param (used by generatePDFv4 Cloud Function)
      if (token === '') {
        const params = new URLSearchParams(document.location.search)
        const customToken = params.get('customToken')
        if (customToken != null) {
          setTokenCookie(decodeURIComponent(customToken))
          token = customToken
        }
      }

      if (token != null && token !== '') {
        try {
          await signInWithCustomToken(token)
          return
        } catch {
          Sentry.configureScope((scope) => scope.setUser(null))
          setAuthenticated(false)
          deleteTokenCookie()
          setLoading(false)
          return
        }
      }

      setLoading(false)
    })

    return () => {
      unsubscribe()
    }
  }, [
    isAuthV1,
    isAuthenticationPage,
    isRequiredActionsPage,
    isRoot,
    isSignOutPage,
    setAuthenticated,
    setLocation,
  ])

  /** @TODO use async/await and React.Suspense */
  if (isLoading || isLoadingFromContext) {
    return <Loader />
  }

  if (isMaintenance) {
    return <MaintenancePage />
  }

  return (
    <Switch>
      <Route path="/signout" component={SignOutPage} />

      <Route path="/authentication">
        {(_params) => <Redirect to={DEFAULT_AUTH_PAGE} />}
      </Route>

      {/**
       * Authentication V1
       */}
      <Route path="/authentication-v1" component={AuthenticationPage} />

      {/**
       * Authentication V2
       */}
      <Route path="/auth/login" component={LoginPage} />
      <Route path="/auth/login/success" component={LoginSuccessPage} />
      <Route path="/auth/signup/invited" component={InvitedPage} />
      <Route path="/auth/signup" component={SignUpPage} />
      <Route path="/auth/required-actions" component={RequiredActionsPage} />
      <Route path="/auth/contact" component={ContactPage} />
      <Route path="/auth" component={AuthPage} />

      <Route path="/remotecapture" component={RemoteCapturePage} />
      <Route path="/company/plans" component={CompanyPlansPage} />
      {isAuthenticated ? (
        <>
          <Route
            path="/company/plans/success"
            component={CompanyPlansSuccessPage}
          />
          <Route
            path="/company/plans/canceled"
            component={CompanyPlansCanceledPage}
          />
          <Route path="/company/subscription" component={SubscriptionPage} />

          <Route path="/reports/edit/:reportId?">
            {() => <ReportsEditPage />}
          </Route>
          <Route path="/reports/v2/:reportId">{() => <ReportsPage />}</Route>
          <Route path="/report-templates/:reportTemplateId">
            {() => <ReportTemplatesPage />}
          </Route>
          <Route path="/preview-reports">{() => <PreviewReportsPage />}</Route>

          <Route path="/reports/view/:reportId">
            {() => <ReportsViewPage />}
          </Route>
          <Route path="/reports/list" component={ReportsListPage} />
          <Route path="/reports/new/:rest*">{() => <NewReportPage />}</Route>
          <Route path="/reports" component={ReportsListPage} />
        </>
      ) : (
        <Fragment />
      )}

      <Route>
        <Redirect to={DEFAULT_AUTH_PAGE} />
      </Route>
    </Switch>
  )
}
