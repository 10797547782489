// eslint-disable-next-line import/order
import './setupDebug.ts'

import { ChakraProvider, ColorModeScript } from '@chakra-ui/react'
import { CacheProvider } from '@emotion/react'
import { setUseWhatChange } from '@simbathesailor/use-what-changed'
import { StrictMode, Suspense } from 'react'
import { createRoot } from 'react-dom/client'
import { QueryClient, QueryClientProvider } from 'react-query'
import { ReactQueryDevtools } from 'react-query/devtools'
import { RecoilRoot } from 'recoil'
import RecoilNexus from 'recoil-nexus'

import './analytics'
import { App } from './App'
import { Loader } from './components/Loader/Loader'
import { emotionCache } from './emotion'
import './firebase/init'
import { FetchParamsProvider } from './gql/FetchParamsContext'
import './i18n'
import './sentry'
import { SWReloadPrompt } from './SWReloadPrompt'
import theme from './theme'
import { colorModeManager } from './utils/colorMode'

import './index.css'

setUseWhatChange(import.meta.env.DEV)

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      queryFn: (context) => {
        if (context.queryKey[0] == null) {
          console.trace()
        }
        throw new Error('no queryFn')
      },
    },
  },
})

const container = document.getElementById('root')
// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
const root = createRoot(container!)
root.render(
  <StrictMode>
    <RecoilRoot>
      <RecoilNexus />
      <QueryClientProvider client={queryClient} contextSharing>
        <FetchParamsProvider>
          <CacheProvider value={emotionCache}>
            <ColorModeScript initialColorMode={theme.config.initialColorMode} />
            <ChakraProvider theme={theme} colorModeManager={colorModeManager}>
              <Suspense fallback={<Loader />}>
                <App />
                <SWReloadPrompt />
              </Suspense>
            </ChakraProvider>
          </CacheProvider>
          {import.meta.env.DEV && (
            <ReactQueryDevtools initialIsOpen={false} position="bottom-left" />
          )}
        </FetchParamsProvider>
      </QueryClientProvider>
    </RecoilRoot>
  </StrictMode>
)
